import usePathwayAdminDropdownItems from "@/admin/pathways/hooks/usePathwayAdminDropdownItems"
import AppsSidebarDragDropProvider from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import AppsSidebarList from "@/apps/sidebar-item/AppsSidebarList"
import { useLabel } from "@/core/context/LabelsContext"
import ProductBadge from "@/main/page/side-bar/temporary-sidebar/ProductBadge"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import ProductStatusChip from "@/product/common/status-chip/ProductStatusChip"
import LeaveProductButton from "@/product/member/admin/enrollment/LeaveProductButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { graphql, useFragment } from "react-relay"

type PathwaySidebarItemProps = {
  productKey: any
  testid: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  isDragging: boolean
}

function PathwaySidebarItem({
  productKey,
  testid = "PathwaySidebarItem",
  dragHandleProps,
  isDragging,
}: PathwaySidebarItemProps) {
  const product = useFragment<any>(
    graphql`
      fragment PathwaySidebarItemFragment on Product {
        id
        name
        slug
        status
        type
        ...usePermissionsFragment
        ...LeaveProductButtonFragment
        viewerMembership {
          id
        }
        ...ProductBadgeFragment
        ...usePathwayAdminDropdownItemsFragment
      }
    `,
    productKey
  )

  const permissions = usePermissions(product)
  const { viewerMembership } = product
  const COURSE_PATHS = generateCoursePaths(product.slug)
  const isViewingProduct = location.pathname.startsWith(`${COURSE_PATHS.ROOT}/`)
  const isSelected = location.pathname === COURSE_PATHS.DASHBOARD
  const isPathway = product.type === "pathway"
  const pathwayLabel = useLabel("pathway")
  const isPathwaysAdmin = permissions.has("pathways.manage")

  const { allItems } = usePathwayAdminDropdownItems({
    pathwayProductKey: product,
    testid,
  })
  const rightContent = getRightContent()

  if (!isPathway) return null

  return (
    <CommunitySidebarItem
      testid={testid}
      name={product.name}
      isDragging={isDragging}
      dragHandleProps={dragHandleProps}
      leftIcon={<ProductBadge isSelected={isSelected} productKey={product} />}
      to={COURSE_PATHS.DASHBOARD}
      selected={isSelected}
      showItemsRoute={COURSE_PATHS.ROOT}
      rightContent={rightContent}
      overflowItems={getOverflowItems()}
      indentNestedItems
    >
      {isViewingProduct && (
        <AppsSidebarDragDropProvider productId={product.id}>
          <AppsSidebarList
            productId={product.id}
            testid={testid}
            SkeletonProps={{ items: 1 }}
          />
        </AppsSidebarDragDropProvider>
      )}
    </CommunitySidebarItem>
  )

  function getRightContent() {
    return (
      <>
        {product.status === "draft" && (
          <ProductStatusChip status={product.status} testid={testid} />
        )}
      </>
    )
  }

  function getOverflowItems() {
    if (!viewerMembership && !isViewingProduct && !isPathwaysAdmin) return undefined
    const items =
      isPathwaysAdmin && allItems.length
        ? allItems
        : viewerMembership
        ? [
            <LeaveProductButton
              key={`${testid}.more-actions.${product.slug}.leave`}
              productKey={product}
            >
              {({ onClick }) => (
                <DiscoDropdownItem
                  onClick={onClick}
                  testid={`${testid}.more-actions.${product.slug}.leave`}
                  title={`Leave ${pathwayLabel.singular}`}
                  icon={"logout"}
                />
              )}
            </LeaveProductButton>,
          ]
        : []
    if (!items?.length) return undefined
    return <>{items}</>
  }
}

export default observer(PathwaySidebarItem)
