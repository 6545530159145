/**
 * @generated SignedSource<<d42af6771eca15220bbf66bb65473441>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MyExperiencesListItemFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly status: ProductStatus;
  readonly viewerMembership: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductBadgeFragment" | "usePermissionsFragment" | "useIsWaitingRoomEnabledActiveProductFragment" | "ExperienceAdminDropdownFragment" | "LeaveProductButtonFragment" | "LearnModeSwitchFragment">;
  readonly " $fragmentType": "MyExperiencesListItemFragment";
};
export type MyExperiencesListItemFragment$key = {
  readonly " $data"?: MyExperiencesListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyExperiencesListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyExperiencesListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductBadgeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsWaitingRoomEnabledActiveProductFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LeaveProductButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnModeSwitchFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "89dd1bc00191fe19c6881d7d4dce381f";

export default node;
