import ROUTE_NAMES from "@/core/route/util/routeNames"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useHistory } from "react-router-dom"

type CreateOrganizationButtonProps = {
  children: OverridableDiscoButtonChildren
  testid?: string
}

export default function CreateOrganizationButton(props: CreateOrganizationButtonProps) {
  const { children, testid = "CreateOrganizationButton" } = props
  const history = useHistory()

  return (
    <OverridableDiscoButton testid={testid} onClick={onClick}>
      {children}
    </OverridableDiscoButton>
  )

  function onClick() {
    history.push(ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED)
  }
}
