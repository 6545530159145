import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoButton, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import { Entitlement } from "@utils/hook/__generated__/useHasEntitlementActiveOrganizationFragment.graphql"
import useHasEntitlement from "@utils/hook/useHasEntitlement"

interface Props {
  testid?: string
  entitlement?: Entitlement
  children?: React.ReactElement
  placement?: "top" | "bottom" | "right" | "left"
}

function PaidFeatureTooltip(props: Props) {
  const activeOrganization = useActiveOrganization()

  const { entitlement, placement, children } = props

  // Hide when the given entitlement is granted.
  const hasEntitlement = useHasEntitlement(entitlement)
  if ((entitlement && hasEntitlement) || !entitlement) return children || null

  return (
    <DiscoTooltip
      interactive
      title={
        entitlement === "saml_sso"
          ? "Upgrade to Enterprise to unlock"
          : "Upgrade to unlock"
      }
      placement={placement}
      content={
        <>
          <DiscoText variant={"body-sm"} color={"groovy.grey.300"} marginBottom={2}>
            {getEntitlementText()}
          </DiscoText>
          <DiscoButton
            component={DiscoLink}
            onClick={() => {
              const url =
                entitlement === "saml_sso"
                  ? activeOrganization?.subscription?.status === "active"
                    ? "https://support.disco.co/hc/en-us/requests/new"
                    : "https://form.typeform.com/to/S1AcyHJx?utm_source=integrations&utm_medium=disco"
                  : `${ROUTE_NAMES.ADMIN.SETTINGS.BILLING.ROOT}?p=1`
              window.open(url, "_blank")
            }}
            testid={`PaidFeatureTooltip.upgrade-button-in-tooltip.${entitlement}`}
            color={"cyan"}
            leftIcon={"lock"}
            width={"100%"}
          >
            {entitlement === "saml_sso" ? "Contact Us" : "View Plans"}
          </DiscoButton>
        </>
      }
    >
      <span>{children}</span>
    </DiscoTooltip>
  )

  function getEntitlementText() {
    switch (entitlement) {
      case "custom_profile_fields":
        return "Upgrade your account to edit existing and add additional custom profile fields."
      case "multiple_zoom_accounts":
        return "Upgrade to add more Zoom accounts to your community."
      case "zapier_integration":
        return "Upgrade to access Disco's Zapier integration and other integrations."
      case "slack_integration":
        return "Upgrade to access Disco's Slack integration and other integrations."
      case "ai_bot":
      case "ai_image_generation":
      case "ai_content_generation":
        return "Upgrade your account to access Disco's AI features."
      case "advanced_analytics":
        return "Upgrade your account to access Disco's advanced analytics."
      case "saml_sso":
        return "Contact us to learn more about Disco's SSO features."
      default:
        return "Upgrade to get access to customized branding, AI, custom domains and more!"
    }
  }
}

export default PaidFeatureTooltip
